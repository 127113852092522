import { RegisterUserInput } from "./dto/registerUserInput";
import { RegisterUserOutput } from "./dto/registerUserOutput";
import { RegisterTenantInput } from "./dto/registerTenantInput";
import { RegisterTenantOutput } from "./dto/registerTenantOutput";
import http from "../httpService";
import { TenantDto } from "../tenant/dto/tenantDto";
import { TenantFeatureDto } from "../tenant/dto/tenantFeatureDto";
import TenantLoginInfoDto from "../session/dto/tenantLoginInfoDto";
import UserLoginInfoDto from "../session/dto/userLoginInfoDto";
import { UserDto } from "../user/dto/userDto";

class AccountService {
  public async registerTenant(
    registerInput: RegisterTenantInput
  ): Promise<RegisterTenantOutput> {
    const result = await http.post(
      "api/services/app/account/registerTenant",
      registerInput
    );
    return result.data.result;
  }
  public async registerUser(
    registerInput: RegisterUserInput
  ): Promise<RegisterUserOutput> {
    const result = await http.post(
      "api/services/app/account/registerUser",
      registerInput
    );
    return result.data.result;
  }
  public async updateTenant(input: TenantDto): Promise<TenantLoginInfoDto> {
    const result = await http.put(
      "api/services/app/account/updateTenant",
      input
    );
    return result.data.result;
  }
  public async updateTenantFeatures(
    features: TenantFeatureDto[]
  ): Promise<TenantFeatureDto[]> {
    const result = await http.put(
      "api/services/app/tenant/updateTenantFeatures",
      features
    );
    return result.data.result;
  }
  public async updateUser(input: UserDto): Promise<UserLoginInfoDto> {
    const result = await http.put("api/services/app/account/updateUser", input);
    return result.data.result;
  }
  public async verifyEmailConfirmationCode(code: string): Promise<UserDto> {
    const result = await http.put(
      `api/services/app/account/verifyEmailConfirmationCode`,
      { code: code }
    );
    return result.data.result;
  }
  public async verifyPhoneConfirmationCode(code: string): Promise<UserDto> {
    const result = await http.put(
      `api/services/app/account/verifyPhoneConfirmationCode`,
      { code: code }
    );
    return result.data.result;
  }
}

export default new AccountService();

import http from '../httpService';
import { StorageFileDto } from './dto/storageFileDto';
import AppConsts from 'src/config/constants';

class StorageFileService {
  public async create(file: any): Promise<StorageFileDto> {
    const result = await http.post("api/services/app/storage/file/create", file);
    return result.data.result;
  }

  public getUrl(id: string) {
    const baseUrl = AppConsts.API_BASE_URL;
    const result = `${baseUrl}api/services/app/storage/file/content/${id}`;
    return result;
  }

  public async get(id: string): Promise<StorageFileDto> {
    const result = await http.get(`api/services/app/storage/file/get/${id}`);
    return result.data.result;
  }

  public async delete(id: string) {
    const result = await http.delete(`api/services/app/storage/file/delete/${id}`);
    return result.data;
  }
}

export default new StorageFileService();

import { AppointmentDto } from "../../services/appointment/dto/appointmentDto";
import http from "../httpService";
import { BookAppointmentDto } from "./dto/bookAppointmentDto";
import { FindAppointmentSlotDto } from "./dto/findAppointmentSlotsDto";
import { CreateAppointmentSlotDto } from "./dto/createAppointmentSlotDto";
import { GetAppointmentSlotsRequestDto } from "./dto/getAppointmentSlotsRequestDto";
import { GetAppointmentsDto } from "./dto/getAppointmentsDto";
import { CancelAppointmentDto } from "./dto/cancelAppointmentDto";
import { RescheduleAppointmentDto } from "./dto/rescheduleAppointmentDto";

class AppointmentService {
  public async get(request: GetAppointmentsDto) {
    const response = await http.get("/api/services/app/appointment/getall", {
      params: request,
    });
    return response.data.result;
  }

  public async create(input: AppointmentDto) {
    let result = await http.post("api/services/app/appointment/create", input);
    return result.data.result;
  }

  public async cancel(body: CancelAppointmentDto) {
    const response = await http.post(
      `/api/services/app/appointment/cancel`,
      body
    );
    return response.data.result;
  }

  public async getAppointmentBySlotId(slotId: number) {
    const response = await http.get(
      "/api/services/app/appointment/getbyslotid",
      {
        params: {
          slotId,
        },
      }
    );
    return response.data.result;
  }

  public async getAppointmentSlots(request: GetAppointmentSlotsRequestDto) {
    const response = await http.get(
      "/api/services/app/appointmentslots/getbydoctor",
      {
        params: request,
      }
    );
    return response.data.result;
  }

  public async createAppointmentSlot(body: CreateAppointmentSlotDto) {
    const response = await http.post(
      "/api/services/app/appointmentslots/create",
      body
    );
    return response.data.result;
  }

  public async updateAppointmentSlot(body: CreateAppointmentSlotDto) {
    const response = await http.put(
      "/api/services/app/appointmentslots/update",
      body
    );
    return response.data.result;
  }

  public async bookAppointment(body: BookAppointmentDto) {
    const response = await http.post(
      "/api/services/app/appointmentslots/book",
      body
    );
    return response.data.result;
  }

  public async reschedule(body: RescheduleAppointmentDto) {
    const response = await http.post(
      "/api/services/app/appointment/reschedule",
      body
    );
    return response.data.result;
  }

  public async reserveSlot(body: any) {
    const response = await http.post(
      "/api/services/app/appointmentslots/reserve",
      body
    );
    return response.data.result;
  }

  public async releaseSlot(body: any) {
    const response = await http.post(
      "/api/services/app/appointmentslots/release",
      body
    );
    return response.data.result;
  }

  public async getSlotsFromEpic(request: FindAppointmentSlotDto) {
    const result = await http.get(
      "/api/services/integration/epic/appointment/find-slots",
      {
        params: request,
      }
    );
    return result.data.result;
  }

  public async bookAppointmentFromEpic(details: BookAppointmentDto) {
    const result = await http.post(
      "/api/services/integration/epic/appointment/book",
      details
    );
    return result.data.result;
  }
}

export default new AppointmentService();

/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import MainLayout from "src/layouts/MainLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

const routesConfig = [
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/app" />
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/Error404View"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register/user",
    component: lazy(() => import("src/views/auth/RegisterUserView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register/tenant",
    component: lazy(() => import("src/views/auth/RegisterTenantView"))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: "/register/complete/:id",
    component: lazy(() => import("src/views/auth/RegisterCompleteView"))
  },
  {
    exact: true,
    path: "/account/activate/:code",
    component: lazy(() => import("src/views/auth/AccountActivationView"))
  },
  {
    exact: true,
    path: "/forgotpassword",
    component: lazy(() => import("src/views/auth/ForgotPasswordView"))
  },
  {
    exact: true,
    path: "/resetpassword",
    component: lazy(() => import("src/views/auth/PasswordResetView"))
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/dashboard" />
      },
      {
        exact: true,
        path: "/app/dashboard",
        component: lazy(() => import("src/views/dashboard/DashboardView"))
      },
      {
        exact: true,
        path: "/app/chat",
        component: lazy(() => import("src/views/chat/ChatView"))
      },
      {
        exact: true,
        path: ["/app/chat/new", "/app/chat/:threadKey"],
        component: lazy(() => import("src/views/chat/ChatView"))
      },
      {
        exact: true,
        path: "/app/appointments/:tab",
        component: lazy(() => import("src/views/appointments"))
      },
      {
        exact: true,
        path: "/app/account/:tab",
        component: lazy(() => import("src/views/account"))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;

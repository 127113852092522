import React from 'react';
import { fade } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { setSearchText } from 'src/actions/searchActions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    paddingRight: theme.spacing(1)
  },
  iconWrapper: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {

    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '18ch',
        '&:focus': {
          width: '26ch',
        },
      },
      '&::placeholder': {
        color: 'white'
      },
    },
  }
}));

const SearchPatients = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.search);

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search for patients..."
        value={searchText}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => dispatch(setSearchText(e.target.value))}
        className={classes.input}
      />
    </div>
  );
};

export default SearchPatients;

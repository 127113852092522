import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '100%'
  }
}));

function Logo({ mode, ...rest }) {
  const classes = useStyles();

  let logo = '/static/images/logo.png';
  if (mode === 'white') {
    logo = '/static/images/logo-white.png';
  } else if (mode === 'horizontal') {
    logo = '/static/images/logo-horizontal.png';
  }

  return <img className={classes.logo} alt="Logo" src={logo} {...rest} />;
}

Logo.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(['regular', 'white', 'horizontal'])
};

Logo.defaultProps = {
  mode: 'regular'
};

export default Logo;

import { ChangeLanguagaInput } from "./dto/changeLanguageInput";
import { ResetPasswordDto } from "./dto/resetPasswordDto";
import { CreateOrUpdateUserInput } from "./dto/createOrUpdateUserInput";
import { EntityDto } from "../../services/dto/entityDto";
import { GetAllUserOutput } from "./dto/getAllUserOutput";
import { PagedResultDto } from "../../services/dto/pagedResultDto";
import { PagedUserResultRequestDto } from "./dto/PagedUserResultRequestDto";
import { UpdateUserInput } from "./dto/updateUserInput";
import { OnlineUserDto } from "./dto/onlineUserDto";
import http from "../httpService";
import PatientOutput from "./dto/patientOutput";

class UserService {
  public async create(createUserInput: CreateOrUpdateUserInput) {
    let result = await http.post(
      "api/services/app/user/create",
      createUserInput
    );
    return result.data.result;
  }

  public async isOnline(
    userId: number,
    tenantId?: number
  ): Promise<OnlineUserDto> {
    let result = await http.get("api/services/app/user/isonline", {
      params: {
        tenantId: tenantId,
        userId: userId,
      },
    });
    return result.data.result;
  }

  public async get(entityDto: EntityDto): Promise<CreateOrUpdateUserInput> {
    let result = await http.get("api/services/app/user/get", {
      params: entityDto,
    });
    return result.data.result;
  }

  public async getAll(
    pagedFilterAndSortedRequest: PagedUserResultRequestDto
  ): Promise<PagedResultDto<GetAllUserOutput>> {
    let result = await http.get("api/services/app/user/getall", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }

  public async getPatients(name: string): Promise<PatientOutput[]> {
    let result = await http.get("api/services/app/user/patients", {
      params: {
        name: name
      }
    });
    return result.data.result;
  }

  public async forgotPassword(email: string) {
    let result = await http.post(
      `api/services/app/user/forgotpassword?email=${email}`
    );
    return result.data;
  }

  public async confirmResetPassword(resetPassword: ResetPasswordDto) {
    let result = await http.post(
      "api/services/app/user/confirmresetpassword",
      resetPassword
    );
    return result.data;
  }

  public async changeLanguage(changeLanguageInput: ChangeLanguagaInput) {
    let result = await http.post(
      "api/services/app/user/changelanguage",
      changeLanguageInput
    );
    return result.data;
  }

  public async update(updateUserInput: UpdateUserInput) {
    let result = await http.put(
      "api/services/app/user/update",
      updateUserInput
    );
    return result.data.result;
  }

  public async delete(entityDto: EntityDto) {
    let result = await http.delete("api/services/app/user/delete", {
      params: entityDto,
    });
    return result.data;
  }
}

export default new UserService();

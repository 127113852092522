import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppConsts from 'src/config/constants';
import { handleThreadCallback, handleMessageCallback } from '../actions/chatActions';
import SignalR from '../utils/signalr';
import Notifications from '../utils/notifications';

function RealTimeMessenger() {
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.account);
  // register event callbacks for messaging system if authenticated user session
  if (session && !SignalR.isConnected()) {
    const callbacks = [
      {
        name: AppConsts.notifications.getNotification,
        callback: (result) => {
          if (result) {

          }
        }
      },
      {
        name: AppConsts.notifications.getThread,
        callback: (result) => {
          if (result) {
            dispatch(handleThreadCallback(result));
            if (session.user.id !== result.creatorUserId) {
              // Notifications.sounds.newThread();
              // Notifications.add('Instant Access', `${result.creatorUser.fullName}`, `You have a new message from ${result.creatorUser.fullName}.`);
            }
          }
        }
      },
      {
        name: AppConsts.notifications.getMessage,
        callback: (result) => {
          if (result) {
            // Check if user needs to receive message
            const messageRole = result.selectedChatRole;
            const { user } = session;
            const { roles } = user;
            const userRoleNames = roles.map((el) => el.name);
            const userHasMessageRole = userRoleNames.indexOf(messageRole) > -1;
            const userCreatedMessage = result.creatorUserId === user.id;
            const isAdmin = userRoleNames.indexOf(AppConsts.roles.tenant.Admin) > -1;
            const isPatient = userRoleNames.indexOf(AppConsts.roles.host.Patient) > -1;

            if (isAdmin || userHasMessageRole || userCreatedMessage || isPatient) {
              dispatch(handleMessageCallback(result));
              if (user.id !== result.creatorUserId) {
                Notifications.sounds.newMessage();
                Notifications.add('Instant Access', `${result.creatorUser.fullName}`, `You have a new message from ${result.creatorUser.fullName}.`);
              }
            } else {
              console.log('Message ignored!');
            }
          }
        }
      }
    ];

    SignalR.connect(callbacks);
  }

  return (
    <>
    </>
  );
}

export default RealTimeMessenger;

import http from '../httpService';
import { MessageDto } from "./dto/messageDto";

class MessageService {
  public async create(formData: FormData): Promise<MessageDto> {
    const result = await http.post('api/services/app/message/create', formData);
    return result.data.result;
  }
}

export default new MessageService();

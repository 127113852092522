import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Divider, Drawer, Hidden, Link, Typography, makeStyles, ListItemSecondaryAction, Badge,
  List, ListItem, ListItemIcon, ListItemText, ListSubheader
} from '@material-ui/core';

import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SearchIcon from '@material-ui/icons/Search';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import storageFileService from 'src/services/storage/storageFileService';
import { getThreads } from 'src/actions/chatActions';
import authService from 'src/services/authentication/authService';
import AppConsts from 'src/config/constants';

const navConfig = {
  subheader: 'Navigation',
  items: [
    {
      title: 'Dashboard',
      href: '/app/dashboard',
      routeMatch: '/app/dashboard',
      icon: TimelineOutlinedIcon,
      roles: [
        AppConsts.roles.host.Admin,
        AppConsts.roles.tenant.Admin,
        AppConsts.roles.tenant.Nurse,
        AppConsts.roles.tenant.Scheduler,

      ]
    },
    {
      title: 'Search',
      href: '/app/dashboard',
      routeMatch: '/app/dashboard',
      icon: SearchIcon,
      roles: [AppConsts.roles.host.Patient]
    },
    {
      title: 'Messages',
      href: '/app/chat',
      routeMatch: '/app/chat',
      icon: ForumOutlinedIcon,
      permissions: [AppConsts.permissions.ManageThreads]
    },
    {
      title: 'Appointments',
      href: '/app/appointments/upcoming',
      routeMatch: '/app/appointments',
      icon: EventAvailableIcon,
      roles: [
        AppConsts.roles.host.Patient,
        AppConsts.roles.tenant.Scheduler
      ]
    },
    {
      title: 'Account',
      href: '/app/account/general',
      routeMatch: '/app/account',
      icon: AccountCircleOutlinedIcon
    }
  ]
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  notActive: {},
  active: {
    color: theme.palette.secondary.main,
  },
}));

function Navatar({ session, ...rest }) {
  if (session.tenant && session.tenant.logoId) {
    return <Avatar src={storageFileService.getUrl(session.tenant.logoId)} {...rest} />;
  } if (session.user && session.user.profilePictureId) {
    return <Avatar src={storageFileService.getUrl(session.user.profilePictureId)} {...rest} />;
  }
  return <Avatar {...rest} />;
}

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const { session, user } = useSelector((state) => state.account);
  const { threads } = useSelector((state) => state.chat);

  useEffect(() => {
    dispatch(getThreads());
  }, [dispatch]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const renderTenantMessageBadge = (item) => {
    if (session.tenant && item.href === '/app/chat') {
      const tenantThreads =
        threads.filter(
          (x) =>
            x.messages.length > 0 &&
            (x.creatorUserId === user.id || x.assignedUserId === user.id)
            && x.messages[x.messages.length - 1].creatorUserId !== user.id
            && x.status === 0
        );
      if (tenantThreads && tenantThreads.length > 0)
        return (
          <ListItemSecondaryAction>
            <Badge color="secondary" overlap="circle" badgeContent={tenantThreads.length} />
          </ListItemSecondaryAction>
        )
    }
    return null;
  };

  const renderPatientMessageBadge = (item) => {
    if (!session.tenant && item.href === '/app/chat') {
      const userThreads = threads.filter((x) =>
        x.messages.length > 0
        && (x.creatorUserId === user.id || x.assignedUserId === user.id)
        && x.messages[x.messages.length - 1].creatorUserId !== user.id);

      if (userThreads && userThreads.length > 0) {
        return (
          <ListItemSecondaryAction>
            <Badge color="secondary" overlap="circle" badgeContent={userThreads.length} />
          </ListItemSecondaryAction>
        );
      }
    }

    return null;

  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account/general">
              <Navatar session={session} className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account/general"
              variant="h5"
              color="primary"
              underline="none"
            >
              {`${user.name} ${user.surname}`}
            </Link>
            {session.tenant && (
              <Typography variant="body2" color="primary">
                {session.tenant.name}
              </Typography>
            )}
          </Box>
        </Box>
        <Divider />
        <List
          subheader={(
            <ListSubheader disableSticky>
              {navConfig.subheader}
            </ListSubheader>
          )}
        >
          {navConfig.items.map((item) => {
            if (item.permissions && item.permissions.length > 0) {
              const canAccess = authService.hasPermissions(item.permissions);
              if (!canAccess) {
                return false;
              }
            } else if (item.roles && item.roles.length > 0) {
              const isPatient = authService.hasRoles(item.roles);
              if (!isPatient) {
                return false;
              }
            }

            const match = matchPath(location.pathname, {
              path: item.routeMatch,
              exact: false
            });

            const selected = Boolean(match);
            return (
              <ListItem
                key={item.title}
                button
                component={RouterLink}
                to={item.href}
                selected={selected}
                className={selected ? classes.active : classes.notActive}
              >
                <ListItemIcon className={selected ? classes.active : classes.notActive}>
                  {React.createElement(item.icon)}
                </ListItemIcon>
                <ListItemText primary={item.title} />
                {renderTenantMessageBadge(item)}
                {renderPatientMessageBadge(item)}
              </ListItem>
            );
          })}
        </List>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

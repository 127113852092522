/* eslint-disable no-param-reassign */
import produce from 'immer';
import { SET_SEARCH_TEXT } from 'src/actions/searchActions';

const initialState = {
  searchText: ''
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TEXT: {
      const { text } = action.payload;
      return produce(state, (draft) => {
        draft.searchText = text;
      });
    }

    default: {
      return state;
    }
  }
};

export default searchReducer;

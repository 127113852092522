import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  IconButton, InputAdornment, Avatar, SvgIcon, TextField, Tooltip, Typography, makeStyles,
  Box, Button, CircularProgress, Drawer, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import MessageIcon from '@material-ui/icons/Message';
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather';
import tenantService from 'src/services/tenant/tenantService';
import threadService from 'src/services/message/threadService';
import storageFileService from 'src/services/storage/storageFileService';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

function SearchAvatar({ item }) {
  if (item.logoId) {
    return <Avatar src={storageFileService.getUrl(item.logoId)} />;
  }
  return <Avatar><ImageIcon /></Avatar>;
}

function Search() {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const { user } = useSelector((state) => state.account);

  const handleOpen = () => {
    history.push('/app/dashboard');
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      const response = await tenantService.getAll({ keyword: value });
      const results = response.items.filter((x) => x.name.toLowerCase() !== 'default');
      setResults(results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      handleSearch();
    }
  };

  const handleCreateThread = async (tenantId) => {
    const response = await threadService.create(user.id, tenantId);
    history.push(`/app/chat/${response.id}`);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h4"
                color="primary"
              >
                Search
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                className={classes.queryField}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                placeholder="Search doctors &amp; specialties"
                value={value}
                onKeyUp={handleKeyUp}
                variant="outlined"
              />
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                  <>
                    <List className={classes.root}>
                      {results && results.map((result) => (
                        <ListItem key={result.id}>
                          <ListItemAvatar>
                            <SearchAvatar item={result} />
                          </ListItemAvatar>
                          <ListItemText primary={result.name} secondary={result.creationTime} />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="message" onClick={() => handleCreateThread(result.id)}>
                              <MessageIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}

export default Search;

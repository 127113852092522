import { SessionInfo } from './dto/sessionInfo';
import http from '../httpService';

class SessionService {
  public async me(): Promise<SessionInfo> {
    const result = await http.get("api/services/app/session/me");
    return result.data.result;
  }
}

export default new SessionService();

// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
const AppConsts = {
  APP_VERSION: process.env.REACT_APP_VERSION,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  APP_BASE_URL: process.env.REACT_APP_APP_BASE_URL,
  ENABLE_REDUX_LOGGER: process.env.REACT_APP_ENABLE_REDUX_LOGGER,
  GA_API_KEY: process.env.REACT_APP_GA_API_KEY,
  GA_MEASUREMENT_ID: process.env.REACT_APP_GA_MEASUREMENT_ID,
  themes: {
    LIGHT: 'LIGHT'
  },
  localization: {
    defaultLocalizationSourceName: 'InstantAccess',
  },
  authorization: {
    encryptedAuthTokenName: 'enc_auth_token',
  },
  roles: {
    host: {
      Admin: 'Authorization.Roles.Host.Admin',
      Patient: 'Authorization.Roles.Host.Patient'
    },
    tenant: {
      Admin: 'Authorization.Roles.Tenant.Admin',
      Nurse: 'Authorization.Roles.Tenant.Nurse',
      Scheduler: 'Authorization.Roles.Tenant.Scheduler'
    }
  },
  permissions: {
    ManageTenant: 'Authorization.Permissions.ManageTenant',
    ManageSubscription: 'Authorization.Permissions.ManageTenant.ManageSubscription',
    ManageUsers: 'Authorization.Permissions.ManageTenant.ManageUsers',
    ManageRoles: 'Authorization.Permissions.ManageTenant.ManageRoles',

    ManageThreads: 'Authorization.Permissions.ManageThreads',
    ArchiveThreads: 'Authorization.Permissions.ManageThreads.ArchiveThreads',
    DeleteThreads: 'Authorization.Permissions.ManageThreads.DeleteThreads'
  },
  notifications: {
    getNotification: 'getNotification',
    getThread: 'notifications.getThread',
    getMessage: 'notifications.getMessage'
  },
  settings: {
    timezone: 'Settings.General.Timezone'
  }
};

export default AppConsts;
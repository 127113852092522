import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { setUserData, logout } from 'src/actions/accountActions'
import authService from 'src/services/authentication/authService';
import AppUtils from 'src/utils/appUtils';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout()),
      });

      if (window.location.href.includes('/account/activate')) {

      }

      authService.handleAuthentication();
      if (authService.isAuthenticated()) {
        const session = await authService.loginInWithToken();
        AppUtils.setSession(session);
        await dispatch(setUserData(session));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;

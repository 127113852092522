import axios from 'axios';
import AppConsts from '../config/constants';
import authService from './authentication/authService';

const qs = require('qs');

const http = axios.create({
  baseURL: AppConsts.API_BASE_URL,
  timeout: 30000,
  paramsSerializer(params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

http.interceptors.request.use(
  (config) => {
    const tokenInfo = authService.getTokenInfo();
    if (tokenInfo) {
      config.headers.common.Authorization = `Bearer ${tokenInfo.accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    setTimeout(() => { }, 1000);

    return Promise.reject(error);
  }
);

export default http;

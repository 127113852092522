import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
//import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Divider, Typography, makeStyles } from '@material-ui/core';
import { logout } from 'src/actions/accountActions';
import storageFileService from 'src/services/storage/storageFileService';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

function Navatar({ classes, session, ...rest }) {
  if (session.user && session.user.profilePictureId) {
    return <Avatar className={classes.avatar} src={storageFileService.getUrl(session.user.profilePictureId)} {...rest} />
  } else {
    return <Avatar className={classes.avatar} {...rest} />
  }
}

function Account() {
  const classes = useStyles();
  //const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      //history.push('/');
      //TODO: hack to clear redux store (page refresh). Should add a proper fix, for example a root reducer, etc.
      //TRK: 5/19/2020
      window.location = '/login';
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}>
        <Navatar classes={classes} session={session} />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {`${session.user.name} ${session.user.surname}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}>
        {/* <MenuItem component={RouterLink} to="/app/social/profile">
          Profile
        </MenuItem> */}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account/general">
              <Navatar classes={classes} session={session} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Typography variant="h5" color="primary">
              {`${session.user.name} ${session.user.surname}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          to="/app/account/general">
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

export default Account;

import * as signalR from '@aspnet/signalr';
import AppConsts from '../config/constants';
import authService from '../services/authentication/authService';

let _connection: signalR.HubConnection;
let _isConnected: boolean = false;
let _callbacks: any[] = []; // ((...args: any[]) => void);
const SignalR = {
  config: {
    autoReconnect: true,
    reconnectTime: 5000,
    maxTries: 8,
    increaseReconnectTime: (time: any) => time * 2
  },
  hub: () => _connection,
  on: (name: string, callback: any) => {
    // specific check here to compare callback function to make sure we do not add duplicate callback functions.
    // callback.toString() basically turns the function into a string w/ content and is then able to compare properly.
    if (!_callbacks.some((x) => x.name === name && x.callback.toString() === callback.toString())) {
      const _callback = { name, callback };
      _callbacks.push(_callback);
      _connection.on(_callback.name, _callback.callback);
    }
  },
  off: (name: string, callback: any) => {
    // specific check here to compare callback function to make sure we do not add duplicate callback functions.
    // callback.toString() basically turns the function into a string w/ content and is then able to compare properly.
    if (_callbacks.some((x) => x.name === name && x.callback.toString() === callback.toString())) {
      const _callback = { name, callback };
      const i = _callbacks.findIndex((x) => x.name === _callback.name && x.callback.toString() === _callback.callback.toString());
      _callbacks = _callbacks.splice(i, 1);
      _callbacks.push(_callback);
      _connection.off(_callback.name, _callback.callback);
    }
  },
  isConnected: () => _isConnected,
  // connect to the server
  connect: (callbackHandlers: any[]) => {
    _callbacks = callbackHandlers;

    // start the connection
    const tokenInfo = authService.getTokenInfo();
    const accessToken = tokenInfo.accessToken ?? '';
    const url = `${AppConsts.API_BASE_URL}signalr?${AppConsts.authorization.encryptedAuthTokenName}=${accessToken}`;
    return SignalR._startConnection(url, SignalR._configureConnection)
      .then((connection: any) => {
        _isConnected = true;
        console.log('SignalR => connected to SignalR server!'); // TODO:remove log
        return connection;
      })
      .catch((error: any) => {
        console.error(error.message);
      });
  },
  // invoke event/method on server
  invoke: (name: string, params: any) => SignalR.hub().invoke(name, params).then((b) => {
    console.log(`invoked ${name}`);
  }),
  // configure the connection
  _configureConnection: (connection: signalR.HubConnection) => {
    // set the common hub
    _connection = connection;

    let tries = 1;
    let { reconnectTime } = SignalR.config;

    // reconnect loop
    function tryReconnect() {
      if (tries > SignalR.config.maxTries) {

      } else {
        connection.start()
          .then(() => {
            reconnectTime = SignalR.config.reconnectTime;
            tries = 1;
            console.log('SignalR => reconnected to SignalR server!');

            // register callback handlers
            SignalR._registerCallbacks(connection);
          }).catch(() => {
            tries += 1;
            reconnectTime = SignalR.config.increaseReconnectTime(reconnectTime);
            setTimeout(() => tryReconnect(), reconnectTime);
          });
      }
    }

    // reconnect if hub disconnects
    connection.onclose((e: any) => {
      if (e) {
        console.error(`SignalR => connection closed with error: ${e}`);
      } else {
        console.log('SignalR => disconnected');
      }

      if (!SignalR.config.autoReconnect) {
        return;
      }

      tryReconnect();
    });
  },
  // Starts a connection with transport fallback - if the connection cannot be started using
  // the webSockets transport the function will fallback to the serverSentEvents transport and
  // if this does not work it will try longPolling. If the connection cannot be started using
  // any of the available transports the function will return a rejected Promise.
  _startConnection: (url: string, configureConnection: any) => (function start(transport): any {
    console.debug(`SignalR => starting connection using ${signalR.HttpTransportType[transport]} transport`);
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url, transport)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    if (configureConnection && typeof configureConnection === 'function') {
      configureConnection(connection);
    }

    return connection.start()
      .then((e) => {
        // register callback handlers
        SignalR._registerCallbacks(connection);
        return connection;
      })
      .catch((error) => {
        console.error(error);
        console.debug(`SignalR => cannot start the connection using ${signalR.HttpTransportType[transport]} transport. ${error.message}`);
        if (transport !== signalR.HttpTransportType.LongPolling) {
          return start(transport + 1);
        }

        return Promise.reject(error);
      });
  }(signalR.HttpTransportType.WebSockets)),
  _registerCallbacks: (connection: { on: (arg0: any, arg1: any) => void; }) => {
    if (_callbacks && _callbacks.length > 0) {
      for (let i = 0; i < _callbacks.length; i++) {
        const callback = _callbacks[i];
        connection.on(callback.name, callback.callback);
      }
    }
  }
};

Object.freeze(SignalR);
export default SignalR;

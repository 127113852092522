import jwtDecode from "jwt-decode";
import http from "../httpService.ts";
import sessionService from "../session/sessionService";
import { useSelector } from "react-redux";

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    http.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const tokenInfo = this.getTokenInfo();

    if (!tokenInfo) {
      return;
    }

    if (this.isValidToken(tokenInfo)) {
      this.setSession(tokenInfo);
    } else {
      this.setSession(null);
    }
  }

  loginWithUserAndConfirmationCode = (userId, confirmationCode) =>
    new Promise((resolve, reject) => {
      http.post("/api/authentication/authenticate", { userId, confirmationCode })
        .then(response => {
          if (response.data.success) {
            this.setSession(response.data.result);
            resolve(response.data.result);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginWithEmailAndPassword = (userNameOrEmailAddress, password) =>
    new Promise((resolve, reject) => {
      http.post("/api/authentication/authenticate", { userNameOrEmailAddress, password })
        .then(response => {
          if (response.data.success) {
            this.setSession(response.data.result);
            resolve(response.data.result);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      sessionService.me()
        .then(response => {
          if (response) {
            resolve(response);
          } else {
            reject();
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = authInfo => {
    if (authInfo) {
      const tokenInfo = {
        accessToken: authInfo.accessToken,
        encryptedAccessToken: authInfo.encryptedAccessToken
      };
      localStorage.setItem("tokenInfo", JSON.stringify(tokenInfo));
      http.defaults.headers.common.Authorization = `Bearer ${tokenInfo.accessToken}`;
    } else {
      localStorage.removeItem("tokenInfo");
      delete http.defaults.headers.common.Authorization;
    }
  };

  getTokenInfo = () => {
    const token = localStorage.getItem("tokenInfo");
    if (token) {
      return JSON.parse(token);
    }
    return null;
  }

  isValidToken = tokenInfo => {
    if (!tokenInfo?.accessToken) {
      return false;
    }

    const accessToken = tokenInfo.accessToken;
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getTokenInfo();

  hasRole = (name) => {
    const { session } = useSelector((state) => state.account);
    if (session && session.user) {
      const roles = session.user.roles ?? [];
      return roles.some(x => x.name.toLowerCase() === name.toLowerCase());
    }

    return false;
  };

  hasRoles = (roleNames) => {
    for (const role of roleNames) {
      const hasRole = this.hasRole(role);
      if (hasRole) {
        return true;
      }
    }

    return false;
  };

  hasPermission = (name) => {
    const { session } = useSelector((state) => state.account);
    if (session && session.user) {
      const permissions = session.user.permissions ?? [];
      return permissions.some(x => x.toLowerCase() === name.toLowerCase());
    }

    return false;
  };

  hasPermissions = (permissions) => {
    for (const permission of permissions) {
      const hasPermission = this.hasPermission(permission);
      if (hasPermission) {
        return true;
      }
    }

    return false;
  };

  isGranted = (permissionName) => {
    return this.hasPermission(permissionName);
  };
}

const authService = new AuthService();

export default authService;

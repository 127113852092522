/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_THREAD,
  GET_THREADS,
  GET_THREAD,
  CLEAR_THREAD,
  UPDATE_THREAD,
  ADD_MESSAGE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  SELECTED_STATUS_TAB,
  THREAD_CALLBACK,
  MESSAGE_CALLBACK
} from 'src/actions/chatActions';
import { ThreadStatus } from 'src/services/message/dto/threadStatus';

const initialState = {
  threads: [],
  thread: { messages: [] },
  message: {},
  onlineUsers: [],
  callbackMessage: {},
  sidebarOpen: false,
  selectedStatusTab: 0 // 0 = all, 1 = assigned, 2 = archived
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_THREAD: {
      const { thread } = action.payload;

      return produce(state, (draft) => {
        const { threads } = draft;
        if (threads && thread) {
          threads.push(thread);
        }
      });
    }

    case GET_THREADS: {
      const { threads } = action.payload;

      return produce(state, (draft) => {
        draft.threads = threads;
      });
    }

    case GET_THREAD: {
      const { thread } = action.payload;

      return produce(state, (draft) => {
        draft.thread = thread;
      });
    }

    case CLEAR_THREAD: {
      return produce(state, (draft) => {
        draft.thread = {};
      });
    }

    case UPDATE_THREAD: {
      const { thread } = action.payload;

      return produce(state, (draft) => {
        const { threads } = draft;
        draft.thread = thread;

        const i = threads.findIndex((x) => x.id === thread.id);
        threads[i] = thread;
      });
    }

    case ADD_MESSAGE: {
      // const { message } = action.payload

      return produce(state, (draft) => {

      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, (draft) => {
        draft.sidebarOpen = false;
      });
    }

    case SELECTED_STATUS_TAB: {
      const { status } = action.payload;

      return produce(state, (draft) => {
        draft.selectedStatusTab = status;
      });
    }

    case THREAD_CALLBACK: {
      const { callbackThread } = action.payload;

      return produce(state, (draft) => {
        const { threads } = draft;
        if (callbackThread && !draft.thread) {
          draft.thread = callbackThread;
        }
        if (threads && callbackThread) {
          const exists = threads.some((x) => x.id === callbackThread.id);
          if (!exists) {
            threads.push(callbackThread);
          } else {
            const i = threads.findIndex((x) => x.id === callbackThread.id);
            threads[i] = callbackThread;
          }
        }
      });
    }

    case MESSAGE_CALLBACK: {
      const { callbackMessage } = action.payload;

      return produce(state, (draft) => {
        const currentThread = draft.thread;
        const { threads } = draft;
        let thread = threads.find((x) => x.id === callbackMessage.threadId);
        if (currentThread?.id === thread?.id) {
          thread = currentThread;
        }
        if (thread && callbackMessage) {
          const exists = thread.messages.some((x) => x.id === callbackMessage.id);
          if (!exists) {
            thread.messages.push(callbackMessage);
          }
        }
        const i = threads.findIndex((x) => x.id === callbackMessage.threadId);
        threads[i] = thread;
      });
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;

import http from '../httpService';
import { ThreadDto } from "./dto/threadDto";
import { PagedResultDto } from "../../services/dto/pagedResultDto";
import { PagedThreadResultRequestDto } from "./dto/PagedThreadResultRequestDto";
import { ArchiveThreadDto } from './dto/archiveThreadDto';

class ThreadService {
  public async create(creatorUserId: number, tenantId: number, selectedChatRole: string, assignedUserId?: number): Promise<ThreadDto> {
    const result = await http.post('api/services/app/thread/create', {
      creatorUserId,
      tenantId,
      selectedChatRole,
      assignedUserId
    });
    return result.data.result;
  }

  public async get(threadId: number): Promise<ThreadDto> {
    const result = await http.get('api/services/app/thread/get', {
      params: { id: threadId }
    });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedThreadResultRequestDto): Promise<PagedResultDto<ThreadDto>> {
    let result = await http.get("api/services/app/thread/getall", {
      params: pagedFilterAndSortedRequest
    });
    return result.data.result;
  }

  public async archive(input: ArchiveThreadDto): Promise<ThreadDto> {
    const result = await http.put('api/services/app/thread/archive', input);
    return result.data.result;
  }

  public async update(thread: ThreadDto): Promise<ThreadDto> {
    const result = await http.put('api/services/app/thread/update', thread);
    return result.data.result;
  }

  public async delete(id: number) {
    const result = await http.delete("api/services/app/tenant/delete", { params: { id: id } });
    return result.data;
  }
}

export default new ThreadService();

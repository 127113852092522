import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Container, List, ListItem,
  makeStyles, ListItemAvatar, ListItemText,
  Avatar, ListItemSecondaryAction, Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { setSearchText } from 'src/actions/searchActions';
import { rolesDataSource } from 'src/utils/dataSources';
import MessageIcon from '@material-ui/icons/Message';
import { useDispatch, useSelector } from 'react-redux';
import authService from 'src/services/authentication/authService';
import threadService from 'src/services/message/threadService';
import userService from 'src/services/user/userService';
import AppConsts from 'src/config/constants';

import storageFileService from 'src/services/storage/storageFileService';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.dark,
    width: '100%'
  },
  item: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(2),
    borderRadius: 5,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    cursor: 'pointer'
  }
}));

const SearchPatientList = () => {
  const { searchText } = useSelector((state) => state.search);
  const { user } = useSelector((state) => state.account);
  const [patients, setPatients] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const isNurse = authService.hasRole(AppConsts.roles.tenant.Nurse);

  const loadPatients = async () => {
    const newPatients = await userService.getPatients(searchText);
    setPatients(newPatients);
  };

  useEffect(() => {
    if (searchText && searchText.length > 0) {
      loadPatients();
    }
  }, [searchText]);

  const createThread = async (patientId) => {
    let selectedChatRole = '';
    if (isNurse) {
      selectedChatRole = rolesDataSource.find((el) => el.title === 'Nurse').name;
    } else {
      selectedChatRole = rolesDataSource.find((el) => el.title === 'Scheduler').name;
    }
    const response = await threadService.create(user.id, user.tenantId, selectedChatRole, patientId);
    dispatch(setSearchText(''));
    history.push(`/app/chat/${response.id}`);
  };

  const openThread = async (id) => {
    await createThread(id);
  };

  const renderItem = (item) => (
    <ListItem button className={classes.item} key={item.id} onClick={() => openThread(item.id)}>
      <ListItemAvatar>
        <Avatar src={storageFileService.getUrl(item.profilePictureId)} />
      </ListItemAvatar>
      <ListItemText>
        <Typography variant="h5" color="primary">{item.fullName}</Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="message"
          onClick={
            () => {
              openThread(item.id);
            }
          }
        >
          <MessageIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );


  const renderItems = () => (
    patients.map((el) => renderItem(el))
  );


  return (
    <Container className={classes.root}>
      <List>
        {renderItems()}
      </List>
    </Container>
  );
};

export default SearchPatientList;

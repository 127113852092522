export const SET_SEARCH_TEXT = '@search/set-search-text';

export function setSearchText(value) {
  return (dispatch) => dispatch({
    type: SET_SEARCH_TEXT,
    payload: {
      text: value
    }
  });
}

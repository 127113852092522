import appointmentService from "src/services/appointment/appointmentService";
import authService from "src/services/authentication/authService";
import accountService from "../services/account/accountService";

export const LOGIN_REQUEST = "@account/login-request";
export const LOGIN_SUCCESS = "@account/login-success";
export const LOGIN_FAILURE = "@account/login-failure";
export const SILENT_LOGIN = "@account/silent-login";
export const LOGOUT = "@account/logout";
export const REGISTER = "@account/register";
export const UPDATE_TENANT = "@account/update-tenant";
export const UPDATE_PROFILE = "@account/update-profile";
export const UPDATE_TENANT_FEATURE = "@account/update-tenant-features";
export const GET_UPCOMING_USER_APPOINTMENTS = "@account/get-upcoming-user-appointments";
export const GET_PAST_USER_APPOINTMENTS = "@account/get-past-user-appointments";
export const CANCEL_UPCOMING_APPOINTMENT = "@account/cancel-upcoming-appointment";
export const RESCHEDULE_USER_APPOINTMENT = "@account/reschedule-appointment";
export const SCHEDULE_USER_APPOINTMENT = "@account/schedule-appointment";
export const SCHEDULE_USER_EPIC_APPOINTMENT = "@account/schedule-epic-appointment";

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const response = await authService.loginWithEmailAndPassword(email, password);
      const session = response.session;
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          auth: response,
          session: session,
          user: session.user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(session) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        session: session,
        user: session.user
      }
    });
}

export function rescheduleUserAppointment(request) {
  return async (dispatch) => {
    const response = await appointmentService.reschedule(request);

    dispatch({
      type: RESCHEDULE_USER_APPOINTMENT,
      payload: {
        updatedAppointment: response
      }
    });

    return response;
  };
}

export function bookUserAppointment(request) {
  return async (dispatch) => {
    const response = await appointmentService.bookAppointment(request);

    dispatch({
      type: SCHEDULE_USER_APPOINTMENT,
      payload: {
        appointment: response
      }
    });

    return response;
  };
}

export function bookUserEpicAppointment(request) {
  return async (dispatch) => {
    const response = await appointmentService.bookAppointmentFromEpic(request);

    dispatch({
      type: SCHEDULE_USER_EPIC_APPOINTMENT,
      payload: {}
    });

    return response;
  };
}

export function getUpcomingUserAppointments(request) {
  return async dispatch => {
    const response = await appointmentService.get(request);

    dispatch({
      type: GET_UPCOMING_USER_APPOINTMENTS,
      payload: {
        appointments: response.items
      }
    });

    return response;
  };
}

export function getPastUserAppointments(request) {
  return async dispatch => {
    const response = await appointmentService.get(request);

    dispatch({
      type: GET_PAST_USER_APPOINTMENTS,
      payload: {
        appointments: response.items
      }
    });

    return response;
  };
}

export function cancelUpcomingAppointment(body) {
  return async dispatch => {
    const response = await appointmentService.cancel(body);

    dispatch({
      type: CANCEL_UPCOMING_APPOINTMENT,
      payload: {
        id: body.appointmentId
      }
    });

    return response;
  };
}

export function logout() {
  return async dispatch => {
    authService.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function registerUser(registerInput) {
  const user = accountService.registerUser(registerInput);
  return user;
}

export function registerTenant(registerInput) {
  const tenant = accountService.registerTenant(registerInput);
  return tenant;
}

// export function updateTenant(tenant) {
//   const request = accountService.updateTenant(tenant);
//   request.then(response =>
//     dispatch({
//       type: UPDATE_TENANT,
//       payload: { tenant: response }
//     })
//   );

//   return request;
// }

// export async function updateProfile(user) {
//   const request = accountService.updateUser(user);
//   const x = dispatch => {
//     request.then(response =>
//       dispatch({
//         type: UPDATE_PROFILE,
//         payload: { user: response }
//       })
//     );
//   };
//   return x;
// }

// this is the reducer - no 'async' on the outer function since it just returns a function
// export const updateTenant = (tenant, error) => {

//   // this one's 'async'
//   return async dispatch => {

//     // wrap in try to listen for Promise rejections - equivalent of '.catch()'
//     try {

//       // wait for the fetch to finish then dispatch the result
//       const response = await accountService.updateTenant(tenant);
//       dispatch({
//         type: UPDATE_TENANT,
//         payload: { tenant: response }
//       });
//     } catch (e) {
//       if (error) {
//         // catch errors from fetch
//         error(e);
//       }
//     }
//   };
// };

export const updateTenantFeature = (features) => {
  return dispatch => {
    const response = accountService.updateTenantFeatures(features);
    response.then(res => {
      const tenantFeatures = [...res];
      features.forEach(ft => {
        const feature = tenantFeatures.find(x => x.featureId === ft.featureId);
        feature.featureName = ft.featureName;
      });

      dispatch({
        type: UPDATE_TENANT_FEATURE,
        payload: { tenantFeatures }
      });
    });

    return response;
  };
};

export const updateTenant = (tenant) => {
  return dispatch => {
    var response = accountService.updateTenant(tenant);
    response.then(x => {
      dispatch({
        type: UPDATE_TENANT,
        payload: { tenant: x }
      });
    });
    return response;
  };
};

export const updateProfile = (user) => {
  return dispatch => {
    var response = accountService.updateUser(user);
    response.then(x => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: { user: x }
      });
    });
    return response;
  };
};
/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_TENANT,
  UPDATE_PROFILE,
  UPDATE_TENANT_FEATURE,
  GET_UPCOMING_USER_APPOINTMENTS,
  GET_PAST_USER_APPOINTMENTS,
  CANCEL_UPCOMING_APPOINTMENT,
  RESCHEDULE_USER_APPOINTMENT,
  SCHEDULE_USER_APPOINTMENT
} from 'src/actions/accountActions';

const initialState = {
  auth: null,
  user: null,
  session: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.auth = null;
        draft.session = null;
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { auth, session } = action.payload;

      return produce(state, (draft) => {
        draft.auth = auth;
        draft.session = session;
        draft.user = session.user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.session = null;
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { auth, session } = action.payload;

      return produce(state, (draft) => {
        draft.auth = auth;
        draft.session = session;
        draft.user = session.user;
      });
    }

    case UPDATE_TENANT: {
      const { tenant } = action.payload;

      return produce(state, (draft) => {
        draft.session.tenant = tenant;
      });
    }

    case UPDATE_TENANT_FEATURE: {
      const { tenantFeatures } = action.payload;

      return produce(state, (draft) => {
        draft.session.tenant.features = tenantFeatures;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        draft.session.user = user;
      });
    }

    case GET_UPCOMING_USER_APPOINTMENTS: {
      const { appointments } = action.payload;

      return produce(state, (draft) => {
        draft.user.upcoming_appointments =
          appointments.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
      });
    }

    case GET_PAST_USER_APPOINTMENTS: {
      const { appointments } = action.payload;

      return produce(state, (draft) => {
        draft.user.past_appointments = appointments;
      });
    }

    case CANCEL_UPCOMING_APPOINTMENT: {
      const { id } = action.payload;

      return produce(state, (draft) => {
        const appointments = [...draft.user.upcoming_appointments];
        const canceled = appointments.find(x => x.id == id);
        canceled.isCanceled = true;

        draft.user.upcoming_appointments = appointments;
      });
    }

    case RESCHEDULE_USER_APPOINTMENT: {
      const { updatedAppointment } = action.payload;

      return produce(state, (draft) => {
        if (draft.user.upcoming_appointments) {
          const upcomingAppointments = [...draft.user.upcoming_appointments];
          const appointments = upcomingAppointments.filter(x => x.id !== updatedAppointment.id);
          appointments.push(updatedAppointment);

          draft.user.upcoming_appointments =
            appointments.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
        }
      });
    }

    case SCHEDULE_USER_APPOINTMENT: {
      const { appointment } = action.payload;

      return produce(state, (draft) => {
        if (draft.user.upcoming_appointments) {
          const appointments = [...draft.user.upcoming_appointments];
          appointments.push(appointment);

          draft.user.upcoming_appointments =
            appointments.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
        }
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;

import threadService from 'src/services/message/threadService';
import messageService from 'src/services/message/messageService';

export const CREATE_THREAD = '@chat/create-thread';
export const GET_THREADS = '@chat/get-threads';
export const GET_THREAD = '@chat/get-thread';
export const CLEAR_THREAD = '@chat/clear-thread';
export const UPDATE_THREAD = '@chat/update-thread';
export const ADD_MESSAGE = '@chat/add-message';
export const OPEN_SIDEBAR = '@chat/open-sidebar';
export const CLOSE_SIDEBAR = '@chat/close-sidebar';
export const SELECTED_STATUS_TAB = '@chat/selected-status-tab';

export const THREAD_CALLBACK = '@chat/thread-callback';
export const MESSAGE_CALLBACK = '@chat/message-callback';

export function createThread(creatorUserId, tenantId) {
  const request = threadService.create({
    creatorUserId,
    tenantId
  });

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: CREATE_THREAD,
        payload: { thread: response }
      });
    });
  };
}

export function getThreads(keyword = null, status = null) {
  const request = threadService.getAll({
    keyword,
    status
  });

  const x = (dispatch) => {
    request.then((response) => {
      dispatch({
        type: GET_THREADS,
        payload: { threads: response.items }
      });
    });
  };

  return x;
}

export function clearThread() {
  return {
    type: CLEAR_THREAD
  };
}

export function getThread(threadId) {
  const request = threadService.get(threadId);

  return (dispatch) => {
    request.then((response) => {
      if (response) {
        dispatch({
          type: GET_THREAD,
          payload: { thread: response }
        });
      }
    });
  };
}

export function updateThread(thread) {
  return (dispatch) => {
    const response = threadService.update(thread);
    response.then((x) => {
      dispatch({
        type: UPDATE_THREAD,
        payload: { thread: response }
      });
    });
    return response;
  };
}

export function addMessage(formData) {
  const request = messageService.create(formData);
  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: ADD_MESSAGE,
        payload: { message: response }
      });
    });
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function setSelectedStatusTab(status) {
  return {
    type: SELECTED_STATUS_TAB,
    payload: { status }
  };
}

export function handleThreadCallback(thread) {
  return {
    type: THREAD_CALLBACK,
    payload: { callbackThread: thread }
  };
}

export function handleMessageCallback(message) {
  return {
    type: MESSAGE_CALLBACK,
    payload: { callbackMessage: message }
  };
}

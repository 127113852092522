import { CreateTenantInput } from "./dto/createTenantInput";
import CreateTenantOutput from "./dto/createTenantOutput";
import { EntityDto } from "../../services/dto/entityDto";
import { TenantDto } from "./dto/tenantDto";
import { GetAllTenantOutput } from "./dto/getAllTenantOutput";
import { PagedResultDto } from "../../services/dto/pagedResultDto";
import { PagedTenantResultRequestDto } from "./dto/PagedTenantResultRequestDto";
import { IsTenantAvaibleOutput } from "./dto/isTenantAvailableOutput";
import UpdateTenantInput from "./dto/updateTenantInput";
import UpdateTenantOutput from "./dto/updateTenantOutput";
import http from "../httpService";

class TenantService {
  public async create(createTenantInput: CreateTenantInput): Promise<CreateTenantOutput> {
    const result = await http.post("api/services/app/tenant/create", createTenantInput);
    return result.data.result;
  }

  public async isTenantAvailable(name: string): Promise<IsTenantAvaibleOutput> {
    const result = await http.get("api/services/app/account/istenantavailable", { params: name });
    return result.data.result;
  }

  public async get(entityDto: EntityDto): Promise<TenantDto> {
    const result = await http.get("api/services/app/tenant/get", { params: entityDto });
    return result.data.result;
  }

  public async getAll(pagedFilterAndSortedRequest: PagedTenantResultRequestDto): Promise<PagedResultDto<GetAllTenantOutput>> {
    const result = await http.get("api/services/app/tenant/getall", { params: pagedFilterAndSortedRequest });
    return result.data.result;
  }

  public async update(updateTenantInput: UpdateTenantInput): Promise<UpdateTenantOutput> {
    const result = await http.put("api/services/app/tenant/update", updateTenantInput);
    return result.data.result;
  }

  public async delete(id: number) {
    const result = await http.delete("api/services/app/tenant/delete", { params: { id: id } });
    return result.data;
  }
}

export default new TenantService();

export const specialtyTypes = [
  'Cardiologist',
  'Colon & Rectal Surgery',
  'Dermatology',
  'Emergency Medicine',
  'Endocrinologist',
  'Family Medicine',
  'Gastroenterologist',
  'General Practice',
  'General Surgery',
  'Gynecological Surgical Oncologist',
  'Hematologist',
  'Hepatologist',
  'Internal Medicine',
  'Interventional Cardiology',
  'Interventional Radiologist',
  'Medical Genetics and Genomics',
  'Nephrologist',
  'Neurological Surgery',
  'Neurology',
  'Neurological Surgery',
  'Obstetrics & Gynecology',
  'Oncologist',
  'Opthalmology',
  'Orthopedic Surgery',
  'Otolaryngology-Head & Neck Surgery',
  'Pathology',
  'Pediatric gastroenterology',
  'Pediatric Surgery',
  'Pediatric Urologist',
  'Pediatrics',
  'Physical Medicine and Rehabilitation',
  'Plastic Surgery',
  'Podiatrist',
  'Preventive Medicine',
  'Psychiatry',
  'Pulmonologist',
  'Radiation Oncologist',
  'Radiologist',
  'Rheumatologist',
  'Surgery-Vascular',
  'Surgical Oncologist',
  'Thoracic Surgery',
  'Transplant Surgery',
  'Urology'
];
export const visitTypes = [
  'In Patient Surgery',
  'Office Visit',
  'Out Patient Procedure'
];

export const appointmentVisitTypes = [
  'Existing Patient - Office visit',
  'New Patient - Office Visit',
  'Surgery Follow up - Office Visit'
];

export const rolesDataSource = [
  { title: 'Admin', name: 'Authorization.Roles.Tenant.Admin' },
  { title: 'Nurse', name: 'Authorization.Roles.Tenant.Nurse' },
  { title: 'Scheduler', name: 'Authorization.Roles.Tenant.Scheduler' }
];

export const expMonths = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
export const expYears = ['2020', '2021', '2022', '2023', '2024', '2025'];
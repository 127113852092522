import { string } from "prop-types";

let session = null;

const AppUtils = {
  setSession: (s) => {
    session = s;
  },
  localizeString: (name) => {
    if (session && name) {
      const strings = session.application.localizationStrings;
      const values = Object.entries(strings);
      const value = values.find(x => x[0] === name)[1];
      if (value) {
        return value;
      }
    }

    return name;
  },
  getThreadUserDisplayInfo: function (thread, currentUserId) {
    const creatorUser = thread.creatorUser;
    const isCreator = creatorUser.id === currentUserId;
    const otherUser = isCreator ? thread.assignedUser : creatorUser;
    const creatorHasTenant = thread.creatorUser.tenantId;
    let avatar = null;
    let name = '';

    if (isCreator) {
      avatar = otherUser?.profilePictureId;
      name = otherUser?.fullName;
    }
    else {
      if (creatorUser.tenantId) {
        avatar = thread.tenant.logoId;
        name = thread.tenant.name;
      } else {
        avatar = creatorUser.profilePictureId;
        name = creatorUser.fullName;
      }
    }

    return {
      isCreator,
      otherUser,
      avatar,
      name
    }
  },
  formatUserFriendlyError: function (error) {

    if (typeof error == 'string') {
      return error;
    }

    if (error?.details && error?.message)
      return `${error.message} - ${error.details}`;
    else if (error?.message)
      return error.message;
    else if (error?.details)
      return error.details;
    else
      return "Unknown error (500), please contact support for assistance.";
  },
  truncate: function (str, max, add) {
    add = add || '...';
    return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
  }
};

Object.freeze(AppUtils);
export default AppUtils;
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon, Button, useMediaQuery
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AppConsts from 'src/config/constants';
import Logo from 'src/components/Logo';
import { isMobile } from 'react-device-detect';
import authService from 'src/services/authentication/authService';
import Account from './Account';
import Search from './Search';
import SearchPatients from './SearchPatients';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === AppConsts.themes.LIGHT
      ? {
        // boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      }
      : {})
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { session } = useSelector((state) => state.account);
  const isScheduler = authService.hasRole(AppConsts.roles.tenant.Scheduler);
  const isNurse = authService.hasRole(AppConsts.roles.tenant.Nurse);
  const renderPatientSearchIfNeeded = () => {
    if (!isScheduler && !isNurse) return null;
    return <SearchPatients />;
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          {!isXs && (
            <>
              <Box flexGrow={1} />
              <Box display="flex" justifyContent="center">
                <Logo mode="horizontal" width="200" height="23" />
              </Box>
            </>
          )}
          {isXs && !session.tenant && (
            <>
              <Box display="flex" justifyContent="flex-start">
                <Logo mode="horizontal" width="200" height="23" />
              </Box>
            </>
          )}
        </Hidden>
        <Hidden mdDown>
          <Button color="inherit" component={RouterLink} to="/">
            <Logo mode="horizontal" width="220" height="23" />
          </Button>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {renderPatientSearchIfNeeded()}

        {/* {session.tenant && <Search />} */}
        {/* <Search />
        <Notifications />
        <Settings /> */}
        {isMobile && (
          <IconButton onClick={() => { window.location.reload(); }}>
            <AutorenewIcon />
          </IconButton>
        )}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
